import React from 'react';
import igIcon from '../icons/ig.svg';
import ytIcon from '../icons/yt.svg';
import '../css/Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h1>HYPER LEAGUE</h1>
          </div>
          <div className="footer-center">
          <p>© {currentYear} All rights reserved</p>
          </div>
          <div className="footer-right">
            <div className="icon-wrapper">
              <img src={ytIcon} alt="YouTube" className="icon" />
            </div>
            <div className="icon-wrapper">
              <img src={igIcon} alt="Instagram" className="icon" />
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;
