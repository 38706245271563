import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: {
          welcome: "Bienvenido a nuestra aplicación",
          home: "Inicio",
          hyperleagues: "Hiperligas",
          introTitle: "Hyper League, el torneo de la Federación Catalana de Fútbol",
          introDescription: "Hyper League, el torneo de la Federación Catalana de Fútbol. Hyper League, el torneo de la Federación Catalana de Fútbol.",
        },
      },
      ca: {
        translation: {
          welcome: "Benvingut a la nostra aplicació",
          home: "Inici",
          hyperleagues: "Hiperlligues",
          introTitle: "Hyper League, el torneig de la Federació Catalana de Futbol",
          introDescription: "Hyper League, el torneig de la Federació Catalana de Futbol. Hyper League, el torneig de la Federació Catalana de Futbol.",
        },
      },
    },
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
